
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import { mask } from "vue-the-mask";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
import EditProductCategoriesModal from "@/components/modals/forms/EditProductCategoriesModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "add-standard-categories-modal",
  directives: { mask },
  props: {
    id: { required: true },
    // data: {
    //     type: Object
    // }
  },

  components: {},
  // created () {
  //       const field = document.querySelector("input[name=test]").value
  //       console.log(field)
  // }

  setup(props) {
    var ids = ref(props.id);
    const formRef = ref<null | HTMLFormElement>(null);
    const addProductStandardMappingModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const cat_attr_count = ref(0);
    const structure_data = ref<any>([]);

    const loadingStandard = ref<boolean>(false);
    const router = useRouter();
    //var formData = ref({})
    const store = useStore();
    var mapping_list: any[] = [];
    const std = ref([]);
    const UOM = ref("");
    const standardJson = ref<any>([]);
    const newTreeNode = ref<any>({});
    const categoryTree = ref([]);
    const newTreeValue = ref<any>([]);
    const treeRef = ref<null | HTMLFormElement>(null);
    const treeProps = ref({
      children: "sub_attributes",
      label: "category_name",
    });
    const grade_data = ref([
      {
        id: "E 275 BO",
        name: "E 275 BO",
      },
      {
        id: "E 250 A",
        name: "E 250 A",
      },
    ]);
    const edge_data = ref([
      {
        id: "Trim",
        name: "Trim",
      },
      {
        id: "Mill",
        name: "Mill",
      },
    ]);
    const plant_data = ref([
      // { id: "Plant 1", Name: "Plant 1" },
      // { id: "Plant 2", Name: "Plant 2" },
      // { id: "Plant 3", Name: "Plant 3" },
      // { id: "Plant 4", Name: "Plant 4" },
    ]);
    const brand_data = ref([]);
    const manufacturer_data = ref([]);

    const thickness_min = ref(0);
    const thickness_max = ref(0);
    const length_min = ref(0);
    const length_max = ref(0);
    getCategoryTree();
    //const inputValue = new Modal(document.getElementById("cat_id"));

    const formData = ref({
      standard_select: "",
      category_id: "",
      manufacturer_select: "",
      plant_select: "",
      brand_select: "",
    });

    // async function getCompanyList(query: string) {
    //   debugger;
    //   if (query.length < 3) {
    //     return;
    //   }

    //   let values = { search_term: query };
    //   await store
    //     .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
    //     .then(({ data }) => {
    //       manufacturer_data.value = data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // }

    const getCompanyList = async () => {
      let values = {
        category_id: ids.value,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_MANUFACTURER_LIST_DATA, values)
        .then(({ data }) => {
          debugger;
          manufacturer_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    async function getBrands() {
      // let values = { trade_type: 1 };
      let values = { company_id: ids.value };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_BRAND_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          brand_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    async function getPlants() {
      // let values = { trade_type: 1 };
      let values = {
        company_id: formData.value["manufacturer_select"],
        company_branch_id: 0,
        page: 1,
        records_per_page: 50,
      };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_PLANT_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          plant_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    getAttributeStructure();

    const getStandardDetailsbyId = async (standard_id) => {
      var values = { standard_id: standard_id, page: 1, records_per_page: 1 };
      await store
        .dispatch(Actions.CUST_GET_PRODUCT_STANDARD_LIST, values)
        .then(({ data }) => {
          std.value = data;
          console.log("standard api res");
          console.log(std.value);
        });
    };

    async function getAttributeStructure() {
      //trade type 1 for Supplier
      let values = {
        category_id: ids.value,
      };
      await store
        .dispatch(
          Actions.CUST_GET_CAT_CAPABILITY_ATTRIBUTE_STRUCTURE_BY_CATEGORY_ID,
          values
        )
        .then(({ data }) => {
          debugger;
          UOM.value = data[0].uom_val;
          let formStructure = data.map((item) => {
            if (item.input_value_required_yn === 0) {
              let value = {};
              value["parentLabel"] = item.attribute_label;
              value["input_value_required_yn"] = item.input_value_required_yn;
              let childAttr = item.sub_attributes.map((child) => {
                let subAttr = {
                  input_value_type: child.input_value_type,
                  attribute_label: child.attribute_label,
                  category_attribute_setting_id:
                    child.category_attribute_setting_id,
                  category_attribute_value:
                    child.input_value_type === "RANGE" ? 0 : "",
                  min_value: 0,
                  max_value: 0,
                  input_value_required_yn: child.input_value_required_yn,
                  attribute_values: child.attribute_values,
                  attribute_name: child.attribute_label.toLowerCase(),
                };
                if (child.input_value_type === "RANGE") {
                  subAttr["range"] = {
                    min: parseFloat(child.min_value),
                    max: parseFloat(child.max_value),
                  };
                }
                cat_attr_count.value++;
                return subAttr;
              });
              value["subAttr"] = childAttr;
              return value;
            } else {
              let value = {
                input_value_type: item.input_value_type,
                attribute_label: item.attribute_label,
                category_attribute_setting_id:
                  item.category_attribute_setting_id,
                category_attribute_value:
                  item.input_value_type === "RANGE" ? 0 : "",
                min_value: 0,
                max_value: 0,
                input_value_required_yn: item.input_value_required_yn,
                attribute_values: item.attribute_values,
                attribute_name: item.attribute_label.toLowerCase(),
              };
              value["parentLabel"] = "";
              if (item.input_value_type === "RANGE") {
                value["range"] = {
                  min: parseFloat(item.min_value),
                  max: parseFloat(item.max_value),
                };
              }
              cat_attr_count.value++;
              return value;
            }
          });
          //      client_select: [
          //   {
          //     required: true,
          //     message: "Client is required",
          //     trigger: "change",
          //   },
          // ],
          structure_data.value = formStructure;
          let rule_stuctrue = {};
          formStructure.map((item) => {
            if (item.subAttr) {
              item.subAttr.map((attr) => {
                let label = attr.attribute_name;
                rule_stuctrue[label] = [
                  {
                    required: true,
                    message: attr.attribute_label + "  is required",
                    trigger: "change",
                  },
                ];
              });
            } else {
              let label = item.attribute_name;
              rule_stuctrue[label] = [
                {
                  required: true,
                  message: item.attribute_label + "  is required",
                  trigger: "change",
                },
              ];
            }
          });
          // rules.value = rule_stuctrue;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    //  const getStandardData = async () => {

    //     loadingStandard.value = true;

    //     const db_data = {"standard_id" : formData.value.id, "page" : 1, "records_per_page" : 1 }

    //     await store.dispatch(Actions.CUST_GET_PRODUCT_STANDARD_LIST, db_data).then(({ data }) => {

    //       if(data){

    //          formData.value. standard_select = data.standard_master_id
    //          formData.value.comm = data['comm_no']
    //          formData.value.title = data['standard_title']
    //          formData.value.lic = data.no_of_lic

    //         loadingStandard.value = false;

    //       }else{
    //          formData.value. standard_select = ""
    //          formData.value.comm = ""
    //          formData.value.title = ""
    //          formData.value.lic = ""

    //         Swal.fire({
    //           text: "Sorry, It's Invalid Standard No.!",
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         });

    //         loadingStandard.value = false;

    //       }

    //     })
    //     .catch(({ response }) => {

    //       Swal.fire({
    //           text: "Sorry, It's Invalid Standard No.!",
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         });

    //         loadingStandard.value = false;
    //     });
    // }

    const setPlantCapacityData = async (data) => {
      debugger;
      var user = JSON.parse(JwtService.getToken());
      // call standard api to fetch standard master id
      //getStandardDetailsbyId(standard_data.standard_select[i])

      let standard_attr_values = ref<any>([]);
      standardJson.value.map((item) => {
        if (item["value"] && item["value"].length != 0) {
          standard_attr_values.value.push({
            standard_id: item["standard_id"],
            standard_attribute_ids: item["value"],
          });
        }
      });

      let cat_attr_values = ref<any>([]);
      let attr_values = structure_data.value.map((item) => {
        let cat_attr_value = {};
        if (item["input_value_required_yn"] === 1) {
          cat_attr_value = {
            category_attribute_setting_id:
              item["category_attribute_setting_id"],
            attribute_min_value:
              item["input_value_type"] === "TEXT" ? 0 : item["min_value"],
            attribute_max_value:
              item["input_value_type"] === "TEXT" ? 0 : item["max_value"],
            attribute_generic_ids:
              item["input_value_type"] === "TEXT"
                ? item["category_attribute_value"]
                : [],
          };
          if (
            item["input_value_type"] === "TEXT" &&
            item["category_attribute_value"] != ""
          ) {
            cat_attr_values.value.push(cat_attr_value);
          }
          if (
            item["input_value_type"] === "RANGE" &&
            (item["min_value"] != 0 || item["min_value"] != "") &&
            (item["max_value"] != 0 || item["max_value"] != "")
          ) {
            cat_attr_values.value.push(cat_attr_value);
          }
        } else {
          item["subAttr"].map((child) => {
            cat_attr_value = {
              category_attribute_setting_id:
                child["category_attribute_setting_id"],
              attribute_min_value:
                child["input_value_type"] === "TEXT" ? 0 : child["min_value"],
              attribute_max_value:
                child["input_value_type"] === "TEXT" ? 0 : child["max_value"],
              attribute_generic_ids:
                child["input_value_type"] === "TEXT"
                  ? child["category_attribute_value"]
                  : [],
            };
            if (
              child["input_value_type"] === "TEXT" &&
              child["category_attribute_value"] != ""
            ) {
              cat_attr_values.value.push(cat_attr_value);
            }
            if (
              child["input_value_type"] === "RANGE" &&
              (child["min_value"] != 0 || child["min_value"] != "") &&
              (child["max_value"] != 0 || child["max_value"] != "")
            ) {
              cat_attr_values.value.push(cat_attr_value);
            }
          });
        }
      });
      if (cat_attr_values.value.length < cat_attr_count.value) {
        Swal.fire({
          text: "Plese fill all the category attributes !",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      const db_data = {
        capability_matrix_id: 0,
        category_id: ids.value,
        company_id: formData.value["manufacturer_select"],
        company_branch_id: formData.value["plant_select"],
        brand_id: formData.value["brand_select"],
        standard_attributes: standard_attr_values.value,
        category_attributes: cat_attr_values.value,
        active: 1,
        created_user_id: user.user_id,
      };

      await store
        .dispatch(Actions.CUST_ADD_PLANT_CAPACITY, db_data)
        .then(({ data }) => {
          if (data.is_success) {
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Plant Capacity has been successfully added.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.push({
                  path: "/products/categories/categories-details/" + ids.value,
                });
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    onMounted(async () => {
      //await setFormData(props.data);
      setCurrentPageBreadcrumbs("New Plant Capacity", ["Cateogry Details"]);
      await getBrands();
      // await getCompanyList();
      await setStandardData("");
      await getStandardGradeJson();
    });

    const rules = ref({
      manufacturer_select: [
        {
          required: true,
          message: "Manufacturer is required",
          trigger: "change",
        },
      ],
      plant_select: [
        {
          required: true,
          message: "Plant is required",
          trigger: "change",
        },
      ],
    });

    const standard = ref([]);
    const setStandardData = async (data) => {
      loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_GET_GRADE_STANDARD, values)
          .then(({ data }) => {
            standard.value = data.page_data;
            console.log(standard.value);
            loading.value = false;
          })
          .catch(({ e }) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    };

    async function getCategoryTree() {
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_TREE)
        .then(({ data }) => {
          debugger;
          categoryTree.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const getStandardGradeJson = async () => {
      loading.value = true;
      var values = { category_id: ids.value };
      await store
        .dispatch(Actions.CUST_GET_STANDARD_JSON_DATA, values)
        .then(({ data }) => {
          debugger;
          standardJson.value = data;
          loading.value = false;
        })
        .catch(({ e }) => {
          console.log(e);
        });
    };

    interface Tree {
      category_name: string;
      category_id: string;
    }

    const handleCheckChange = (
      data: Tree,
      checked: boolean,
      indeterminate: boolean
    ) => {
      debugger;
      formData.value["standard_select"] = "";
      manufacturer_data.value = [];
      if (checked) {
        treeRef.value?.setCheckedKeys([data.category_id], true);
        newTreeValue.value = [data.category_id];
        formData.value["category_select"] = data.category_id;
        newTreeNode.value = data;
        // getAttributeList(data.category_id);
        // getStandardData(data.category_id);
        getStandardGradeJson();
        getAttributeStructure();
        // getCompanyList(data.category_id);
      } else {
        if (data.category_id === newTreeValue.value[0]) {
          treeRef.value?.setCheckedKeys([], false);
          newTreeValue.value = [];
          formData.value["category_select"] = "";
          newTreeNode.value = data;
        } else {
          treeRef.value?.setCheckedKeys(newTreeValue.value, false);
          formData.value["category_select"] = newTreeValue.value[0]
            ? newTreeValue.value[0]
            : "";
          //   table_data.value = [];
          //   standard_data.value = [];
          standardJson.value = [];
          structure_data.value = [];
        }
      }
      console.log(data, checked, indeterminate);
    };
    //     const setProductStandardData = async (data) => {
    //       //var user = JSON.parse(JwtService.getToken());
    //       const db_data = {
    //             "standard_id" : data.id,
    //             "standard_no": data.num,
    //             "standard_master_id": data.standard_select,
    //             "comm_no": data.comm,
    //             "standard_title": data.title,
    //             "no_of_lic": data.lic,
    //             "active": false
    //         }

    //         await store.dispatch(Actions.CUST_UPDATE_PRODUCT_STANDARD, db_data).then(({ data }) => {
    //         console.log(data);

    //         if (data)
    //         {
    //             setTimeout(() => {
    //               loading.value = false;
    // //
    //               Swal.fire({
    //                 text: "Product Standard has been successfully edited.",
    //                 icon: "success",
    //                 buttonsStyling: false,
    //                 confirmButtonText: "Ok, got it!",
    //                 customClass: {
    //                   confirmButton: "btn btn-primary",
    //                 },
    //               }).then(() => {
    //                 hideModal(editProductStandardModalRef.value);
    //                 router.go(0)
    //               });
    //             }, 2000)

    //           } else {

    //             loading.value = false;

    //             Swal.fire({
    //               text: "Sorry, looks like there are some errors detected, please try again.",
    //               icon: "error",
    //               buttonsStyling: false,
    //               confirmButtonText: "Ok, got it!",
    //               customClass: {
    //                 confirmButton: "btn btn-primary",
    //               },
    //             })
    //             return false;
    //           }
    //         })
    //       .catch(({ response }) => {
    //         console.log(response);
    //       });
    //     };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setPlantCapacityData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      setStandardData,
      standard,
      std,
      getStandardDetailsbyId,
      //getStandardData,
      addProductStandardMappingModalRef,
      ids,
      thickness_min,
      thickness_max,
      length_min,
      length_max,
      manufacturer_data,
      brand_data,
      plant_data,
      edge_data,
      grade_data,
      standardJson,
      UOM,
      structure_data,
      getStandardGradeJson,
      getAttributeStructure,
      getCompanyList,
      getBrands,
      getPlants,
      handleCheckChange,
      cat_attr_count,
      newTreeValue,
      newTreeNode,
      treeProps,
      categoryTree,
    };
  },
});
